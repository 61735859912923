<template>
  <div class="desktop">
    <h2>{{ advisor ? "Seja bem vindo!" : title }}</h2>
    <h1>
      {{ advisor ? "Plataforma Digital do Seguro Garantia" : subtitle1 }}
      <hr class="my-4" />
    </h1>
    <p class="w-1/2 text-justify">
      {{
        advisor
          ? "Uma plataforma tecnológica para você apoiar seus corretores parceiros de maneira fácil, intuitiva e 100% digital"
          : subtitle2
      }}
    </p>
  </div>
</template>
<script>
import environment from "../../../environment";
export default {
  name: "static-text",
  data() {
    return {
      applicationConfig: {}
    };
  },
  props: ["advisor"],
  computed: {
    title: {
      get() {
        const val = (((this.applicationConfig || {}).layout || {}).login || {})
          .title;
        if (val) return val;
        else return "Seja bem vindo!";
      }
    },
    subtitle1: {
      get() {
        const val = (((this.applicationConfig || {}).layout || {}).login || {})
          .subtitle1;
        if (val) return val;
        else return "OnPoint";
      }
    },
    subtitle2: {
      get() {
        const val = (((this.applicationConfig || {}).layout || {}).login || {})
          .subtitle2;
        if (val) return val;
        else
          return "Uma plataforma tecnológica que chega para revolucionar a forma com que o mercado segurador se relaciona com seus clientes.";
      }
    }
  },
  beforeMount() {
    this.applicationConfig = environment.getApplicationConfig();
  }
};
</script>
<style lang="scss" scoped>
$hr: rgba(var(--vs-primary), 1);

h1,
h2,
p {
  color: white;
}

h1 {
  font-size: 41px;

  hr {
    background: $hr;
    border: none;
    border-radius: 5px;
    height: 7px;
    width: 3.2em;
  }
}

h2 {
  font-size: 34px;
  font-weight: normal;
}

p {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
