<template>
  <div>
    <el-dialog
      title="Warning"
      :visible="true"
      width="30%"
      center
    >
      <span
        >It should be noted that the content will not be aligned in center by
        default</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CustomCard from "../../layouts/components/custom-card/CustomCard.vue";
import StaticText from "../../layouts/components/static-text/StaticText.vue";

import instance from "../../axios";

export default {
  components: {
    CustomCard,
    StaticText
  },

  data() {
    return {
      email: ""
    };
  },
  methods: {
    sendRecoveryEmail() {
      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        const payload = {
          Username: this.email
        };

        return instance({
          method: "post",
          url: "/api/Security/RequestRecoveryPassword",
          data: payload
        })
          .then(() => {
            this.$onpoint.successModal(
              "Link de recuperação de senha enviado. Enviamos um link de recuperação de senha para o seu e-mail."
            );
            setTimeout(() => {
              this.$router.push("login");
            }, 4000);
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.item-direction {
  justify-self: end;
}
</style>
