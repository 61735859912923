<template>
  <div class="grid grid-cols-1 lg:grid-cols-2">
    <static-text />

    <div
      class="m-0 w-full justify-self-end md:my-16 md:w-3/5 lg:w-4/5 xl:max-w-md"
    >
      <custom-card title="Digite seu CNPJ" class="py-6">
        <ValidationObserver v-slot="{ invalid }">
          <form>
            <ValidationProvider
              name="CNPJ"
              rules="required|cnpj"
              v-slot="{ errors }"
            >
              <vs-input
                type="text"
                name="cnpj"
                size="large"
                label-placeholder="CNPJ"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                masked="false"
                class="w-full mt-20"
              />

              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <vs-button
              size="large"
              :disabled="invalid"
              @click="onSubmit"
              class="w-full mt-32 mb-12"
            >
              Iniciar cadastro
            </vs-button>
          </form>
        </ValidationObserver>
      </custom-card>
    </div>

    <el-dialog :visible.sync="recoveryModal" width="50%" center>
      <div class="text-center">
        <i
          class="el-icon-warning"
          style="color: #f29d00;
    font-size: 43px;"
        ></i
        ><br />
        <h1 style="color: #585858; font-size: 35px; font-weight: 700;">
          Atenção!
        </h1>

        <h2
          style="color: #646464;
    margin-top: 20px;"
        >
          A corretora já encontra-se cadastrada no sistema!
        </h2>
      </div>
      <span slot="footer" class="dialog-footer">
        <p style="margin-bottom: 12px; font-weight: 500;">
          Deseja reenviar o e-mail de cadastro de senha, clique aqui
        </p>
        <el-button @click="recoveryModal = false">Cancelar</el-button>
        <el-button
          type="primary"
          @click="
            recoveryModal = false;
            sendRecoveryEmail();
          "
          >Enviar senha</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CustomCard from "../../layouts/components/custom-card/CustomCard.vue";
import StaticText from "../../layouts/components/static-text/StaticText.vue";
import RecoveryPassword from "../../components/recovery-password/index.vue";
import instance from "../../axios";
import { mask } from "vue-the-mask";
import * as Utils from "../../utils/utils";
import * as Validators from "../../validators/index";

export default {
  components: {
    CustomCard,
    StaticText,
    RecoveryPassword
  },
  directives: { mask },
  data() {
    return {
      loadingPopup: false,
      cnpj: "",
      errorPopup: false,
      errorsMessage: [],
      recoveryModal: false
    };
  },
  methods: {
    ...mapActions("cadastro-corretora", ["buscarInformacoesNovaCorretora"]),

    onSubmit() {
      if (this.cnpj) {
        //Se for diferente de 1 signifca que é filial e não Matriz, logo não pode se registrar;
        const cnpj = Utils.removeSpecialChars(this.cnpj);
        const subsidiary = Validators.isSubsidiary(this.cnpj);

        //removendo validação de filial
        // if (subsidiary[3] !== '1') {
        //   return this.$onpoint.errorModal('Erro, Esse CNPJ não é válido pois ele é de uma filial. Utilize o CNPJ da matriz')
        // }

        this.$onpoint.loadingModal(
          "Aguarde, seus dados estão sendo processados",
          () => {
            return this.buscarInformacoesNovaCorretora(cnpj)
              .then(() => this.$router.push(`completar-cadastro/${cnpj}`))
              .catch(errors => {
                if (
                  errors.response.data.Errors[0] ===
                  "Erro! A corretora já encontra-se cadastrada no sistema!"
                ) {
                  this.recoveryModal = true;
                } else {
                  this.$onpoint.errorModal(errors.response.data.Errors);
                }
              });
          }
        );
      }
    },
    sendRecoveryEmail() {
      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        const payload = {
          person: {
            CpfCnpj: this.cnpj
          }
        };
        return instance({
          method: "post",
          url: "/api/Security/RequestRecoveryPasswordBroker",
          data: payload
        })
          .then(() => {
            this.$onpoint.successModal(
              "Link de recuperação de senha enviado. Enviamos um link de recuperação de senha para o seu e-mail."
            );
            setTimeout(() => {
              this.$router.push("login");
            }, 4000);
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-iniciar-cadastro",
      url: window.location.href
    });
  }
};
</script>
<style lang="scss" scoped>
.justify-self-end {
  justify-self: end;
}
</style>
